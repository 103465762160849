@font-face {
  font-family: "FiraSans";
  src: local("/src/assets/Fonts/FiraSans-Light.ttf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "FiraSans";
  src: local("/src/assets/Fonts/FiraSans-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "FiraSans";
  src: local("/src/assets/Fonts/FiraSans-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Iceland";
  src: local("/src/assets/Fonts/Iceland-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "MyriadPro";
  src: local("/src/assets/Fonts/MyriadPro-Regular.otf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "FiraSans";
  src: local("/src/assets/Fonts/FiraSans-Thin.ttf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "FiraSans";
  src: local("/src/assets/Fonts/FiraSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "FiraSans";
  src: local("/src/assets/Fonts/FiraSans-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "FiraSans";
  src: local("/src/assets/Fonts/FiraSans-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "FiraSans";
  src: local("/src/assets/Fonts/FiraSans-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "FiraSans";
  src: local("/src/assets/Fonts/FiraSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "FiraSans";
  src: local("/src/assets/Fonts/FiraSans-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "FiraSans";
  src: local("/src/assets/Fonts/FiraSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "FiraSans";
  src: local("/src/assets/Fonts/FiraSans-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Montserrat";
  src: local("/src/assets/Fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat";
  src: local("/src/assets/Fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2 {
  font-family: "Montserrat" !important;
  font-size: 22px !important;
  font-weight: 500 !important;
}

h3 {
  font-family: "Montserrat" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

h5,
a,
th,
td,
span,
div {
  font-family: "Montserrat" !important;
  font-size: 14px;
  font-weight: 400 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .ant-menu-sub {
  background-color: black !important;
}

.ant-menu-dark {
  background-color: black !important;
}

.ant-layout-sider-children {
  background-color: black !important;
} 

.ant-layout-header.site-layout-sub-header-background {
  background-color: white !important;
}

.ant-layout-header {
  padding-left: 20px !important;
  background-color: black !important;
}*/

.gradient-border {
  border-bottom: 1px solid;
  border-image: linear-gradient(to right, #ff0000, #fffd38, #00ff00) 100% 1;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: inherit !important;
}

.required-star {
  color: red !important;
  font-size: 11px !important;
}

.view-content-dialog .ant-modal-confirm-title {
  font-weight: bolder !important;
}

.sider-menu .ant-menu-submenu-open,
.sider-menu .ant-menu,
.sider-menu .ant-menu-item,
.sider-menu .ant-menu-inline.ant-menu-sub {
  background-color: black;
}

.sider-menu
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: #fa3532;
}

.sider-menu .ant-menu-dark .anticon {
  color: #fa3532;
}

.sider-menu
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  .ant-icon {
  color: #fff;
}

.sider-menu div,
.sider-menu span,
.sider-menu a {
  color: #d2d2d2;
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
}

.ant-menu-item-selected span {
  color: #fff;
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
}

.ant-btn-primary {
  background-color: #fa3532 !important;
  border: #fa3532 !important;
}

.ant-btn-link {
  color: #fa3532 !important;
}

.ant-pagination-item-active {
  border-color: #fa3532 !important;

  a {
    color: #fa3532 !important;
  }
}

@primary: #fa3532;

.ant-btn[disabled] {
  background-color: primary;
  color: white;
  text-shadow: none;
  box-shadow: none;
  opacity: 0.5;
}
.ant-form-item-explain-error {
  font-size: 0.75rem;
}
