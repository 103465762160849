.gift-icon-selector .ant-select-selector {
  height: 50px !important;
}

.gift-icon-selector input {
  height: 50px !important;
}

.gift-icon-selector .ant-select-selection-placeholder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.gift-icon-selector .ant-form-item-label {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
